@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,500;0,600;0,700;1,400;1,700&display=swap");

body {
  font-family: "Raleway", sans-serif;
  font-feature-settings: "pnum" on, "lnum" on;
  font-size: 14px;
  color: #000;
  background: #f2f7ff;
}

img {
  max-width: 100%;
}

a,
.btn {
  transition: all 0.5s;
  text-decoration: none;
}

.btn:focus,
.form_control:focus {
  box-shadow: none;
}

:focus {
  outline: 0 !important;
}

ul,
li {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

h6 {
  line-height: 1.38;
  font-weight: 600;
}

p:last-child {
  margin-bottom: 0;
}

hr {
  background: #d9d9d9;
  opacity: 1;
}

label {
  font-size: 14px;
  line-height: 1.35;
  font-weight: 600;
}

.form_group {
  margin-bottom: 36px;
}

.form_control,
.form_select {
  color: #000;
  font-size: 14px;
  line-height: 1.3;
  border-radius: 0;
  border: 1px solid #aeaeae;
  background-color: #f9f9f9;
  padding: 12px 14px;
}

.form_control::-webkit-input-placeholder {
  /* Edge */
  color: #000;
}

.form_control:-ms-input-placeholder {
  /* Internet Explorer */
  color: #000;
}

.form_control::placeholder {
  color: #000;
}

.form_control.form_small,
.form_select.select_small {
  width: 137px;
  height: 34px;
  display: inline-block;
  font-size: 12px;
  padding: 7px 9px;
}

.select_dropdown {
  display: block;
  width: 100%;
  padding: 12px 14px;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.3;
  color: #000;
  background-color: #f9f9f9;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #aeaeae;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form_select.select_small {
  width: auto;
  padding-right: 40px;
  background-position: right 9px center;
}

.form_check {
  padding-left: 27px;
}

.form_check_inline:last-child {
  margin-right: 0;
}

.form_check .form_check_input {
  margin-left: -27px;
}

.form_check_input[type="checkbox"],
.radio_custom .form_check_input[type="radio"] {
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: #f4f4f4;
  border: 1px solid #5b5b5b;
}

.form_check_input:focus {
  box-shadow: none;
}

.customCheckBox {
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: #f4f4f4;
  border: 1px solid #5b5b5b;
  appearance: "none";
  background-repeat: "no-repeat";
  background-position: "center";
  background-color: "#f4f4f4";
}

.customCheckBox:checked[type="radio"] {
  border-color: #fdd817;
  /* background-image: url(../images/check.png); */
  background-image: url(../../../assets/images2/check.png);
  background-size: 13px 10px;
  background-position: center center;
}

.form_check_input:checked[type="checkbox"],
.radio_custom .form_check_input:checked[type="radio"] {
  border-color: #fdd817;
  /* background-image: url(../images/check.png); */
  background-image: url(../../../assets/images2/check.png);
  background-size: 13px 10px;
  background-position: center center;
}

.final_checkbox[type="radio"] {
  margin-left: -27px;
  width: 20px;
  height: 20px;
  border-radius: 0;
  background-color: #f4f4f4;
  border: 1px solid #5b5b5b;
}

.final_checkbox:focus {
  box-shadow: none;
}

.final_checkbox:checked {
  border-color: #fdd817;
  background-image: url(../../../assets/images2/check.png);
  background-size: 13px 10px;
  background-position: center center;
}

.tab_container ul > li > a {
  background-color: white;
  color: red;
  width: 100px;
  text-align: center;
}
.myClass ul > li > a:hover {
  background-color: pink;
}

label.form_check_label {
  margin-top: 4px;
  font-weight: normal;
}

.checkbox_mrg .form_check:not(:last-child) {
  margin-bottom: 14px;
}

.fw_semi label.form_check_label {
  font-weight: 600;
}

.mt_13 {
  margin-top: 13px;
}

.mt_40 {
  margin-top: 40px;
}

.mb_30 {
  margin-bottom: 30px;
}

.pb_100 {
  padding-bottom: 100px;
}

.bg_yellow {
  background: #fdd817;
}

.bg_lightGray {
  background: #f6f6f6;
}

.btn {
  font-size: 16px;
  font-weight: 600;
  min-width: 155px;
  height: 43px;
  border-radius: 0;
  padding: 9px 20px;
  border: 2px solid transparent;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #000;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}

.btn:hover:before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.btn:not(:last-child) {
  margin-right: 12px;
}

.btn.btn_small {
  min-width: 121px;
  height: 34px;
  font-size: 14px;
}

.btn.btn_yellow {
  color: #000;
  background: #fdd817;
  border: 0;
}

.btn.btn_yellow:before {
  background: #000;
}

.btn.btn_yellow:hover {
  color: #fdd817;
  /* border-color: #000; */
}

.btn.btn_red {
  color: #fff;
  background: #d1242a;
  border-color: #d1242a;
}

.btn.btn_red:before {
  background: #fff;
}

.btn.btn_red:hover {
  color: #d1242a;
}

.btn_red_disabled {
  color: #d1242a;
  background: #fff;
}

a.btn.btn_gray {
  color: #fff;
  background: #a6a6a6;
  border-color: #a6a6a6;
}

button.btn.btn_gray {
  color: #fff;
  background: #a6a6a6;
  border-color: #a6a6a6;
}

.btn.btn_gray:before {
  background: #fff;
}

.btn.btn_gray:hover {
  color: #a6a6a6;
}

button.btn.Verify_btn_gray {
  color: #000;
  background: #a6a6a6;
  border-color: #a6a6a6;
}

.btn.Verify_btn_gray:before {
  background: #000;
}

.btn.Verify_btn_gray:hover {
  color: #ffffff;
}

.btn.btn_logOut {
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 18px 13px 18px 16px;
  height: 62px;
  background: #fafafa;
  border-radius: 50px;
  box-shadow: 0px 4px 4px 0px #0000000f;
  display: inline-flex;
  align-items: center;
  border: 0;
}

.btn.btn_logOut:before {
  background: #d12329;
}

.btn.btn_logOut img {
  margin-left: 14px;
  transition: all 0.5s;
}

.btn.btn_logOut:hover {
  color: #fff;
}

.btn.btn_logOut:hover img {
  filter: brightness(0) invert(1);
}

.heading {
  background: #feee9a;
  padding: 17px 20px;
}

.heading h5 {
  font-size: 18px;
  line-height: 1.39;
  font-weight: 600;
  text-transform: uppercase;
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  position: relative;
}

/* siderbar  */
#sidebar_wrapper {
  z-index: 1000;
  position: fixed;
  left: 352px;
  width: 0;
  height: 100%;
  margin-left: -352px;
  overflow-y: auto;
  overflow-x: hidden;
  background: #fff;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar_wrapper {
  width: 352px;
}

.sidebar_brand {
  position: absolute;
  top: 0;
  width: 352px;
  text-align: center;
  padding: 20px 0;
}

.sidebar_nav {
  position: absolute;
  top: 139px;
  width: 352px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar_nav > li {
  /* text-indent: 10px; */
  margin-bottom: 3px;
}

.sidebar_nav > li a {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  display: flex;
  align-items: center;
  background: #f2f7ff;
  border-right: 6px solid transparent;
  padding: 11px 14px;
}

.sidebar_nav > li a.active {
  border-color: #d12329;
}

.sidebar_nav > li a.active,
.sidebar_nav > li a:hover {
  background: #fdd817;
}

/* .sidebar_nav > li > link div.active {
  border-color: #d12329;
}

.sidebar_nav > li > link div.active,
.sidebar_nav > li > link div:hover {
  background: #fdd817;
} */

.sidebar_nav > a > div {
  font-size: 13px;
  font-weight: 500;
  color: #000;
  /* display: flex; */
  /* align-items: center; */
  background: #f2f7ff;
  border-right: 6px solid transparent;
  padding: 11px 14px;
}

.sidebar_nav > li a img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: left center;
  margin-right: 10px;
}

.ml_main {
  margin-left: 23px;
  transition: all 0.5s ease;
}

.main_header {
  padding: 16px 7px 21px;
}

.main_header .navbar_brand {
  color: #000;
  transition: all 0.5s ease;
}

.navbar_brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}

.main_header .navbar_brand:hover {
  color: #d12329;
}

.tab_block .nav_tabs {
  border: 0;
  margin: 0 11px;
}

.tab_block .nav_tabs .nav_item:not(:last-child) {
  margin-right: 36px;
}

.tab_block .nav_tabs .nav_link {
  font-size: 16px;
  font-weight: 400;
  padding: 10px 11px 10px 14px;
  margin: 0;
  position: relative;
  color: #000;
  border: 0;
  min-width: 148px;
  text-align: center;
}

.tab_block .nav_tabs .nav_link:after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;

  border-top: 9px solid #d12329;
  display: none;
  transition: all 0.5s;
  z-index: 11;
}

.tab_block .nav_tabs .nav_link.active {
  color: #fff;
  background: #d12329;
  border: 0;
  border-radius: 0;
}

.tab_block .nav_tabs .nav_link.active:after {
  display: block;
}

.table_noBg {
  width: 100%;
  line-height: 1.3;
}

.table_noBg th {
  font-size: 14px;
  padding: 10px 10px 7px;
  vertical-align: top;
}

.table_noBg th span {
  font-size: 10px;
  font-weight: normal;
  display: block;
  line-height: 1.2;
}

.table_noBg td {
  font-size: 12px;
  font-weight: 400;
  border-bottom: 1px solid #d9d9d9;
  padding: 12px 10px 11px;
}

.table_custom {
  width: 100%;
  text-align: center;
}

.table_custom thead {
  background: #f2f7ff;
  border-bottom: 1px solid #a8a8a8;
}

.table_custom th {
  font-size: 14px;
  font-weight: 600;
  padding: 26px 10px 14px;
  white-space: nowrap;
}

.table_custom td {
  font-size: 13px;
  font-weight: 400;
  border-bottom: 1px solid #d9d9d9;
  padding: 10px 10px;
}

.table_custom tr:last-child td {
  border: 0;
}

.table_custom td img {
  margin-right: 19px;
}

.table_custom td img:last-child {
  margin-right: 0;
}

.table_custom tr:first-child td {
  padding-top: 20px;
}

.diversion_table th:last-child,
.diversion_table td:last-child,
.diversion_table td:nth-child(3) {
  white-space: nowrap;
}

.diversion_table td:last-child {
  border: 0;
  min-width: 70px;
}

.diversion_block {
  margin: 35px 33px 0 0;
}

.yumbaNo_table td:nth-child(3) {
  white-space: nowrap;
}

.person_block {
  padding: 30px 102px 44px 44px;
}

.person_block + .heading {
  padding-left: 44px;
}

.person_clock hr {
  margin: 20px 0;
}

.no_contact_block {
  margin-left: 18px;
}

.no_contact_block .btn {
  margin-left: 13px;
}

.clientWellbeing_block {
  padding: 13px 20px 32px;
}

.clientWellbeing_block .form_select {
  width: auto;
  border: 0;
  background-color: transparent;
  padding: 0 7px;
  padding-right: 25px;
  font-size: 12px;
  background-position: center right;
  background-size: 12px;
  text-overflow: ellipsis;
}

.clientWellbeing_block .table_responsive {
  margin-bottom: 35px;
}

.clientWellbeing_block .table_noBg th:first-child {
  min-width: 110px;
}

.clientWellbeing_block .table_noBg th:nth-child(2) {
  min-width: 130px;
}

.clientWellbeing_block .table_noBg th:last-child {
  min-width: 100px;
}

/* .clientWellbeing_block table td span {
  margin-left: 18px;
} */

.clientWellbeing_block h6 {
  margin-bottom: 23px;
  text-transform: uppercase;
}

.clientWellbeing_block .btn {
  margin-bottom: 26px;
}

.clientWellbeing_block textarea.form_control {
  margin-bottom: 20px;
}

.clientDetails_block {
  padding: 28px 28px 0 20px;
}

.clientDetails_block .form_group {
  margin-bottom: 43px;
}

.personalBelongings_block .form_group {
  margin-bottom: 25px;
}

.personalBelongings_block label:not(.form_check_label) {
  font-size: 18px;
  margin-bottom: 8px;
}

.personalBelongings_block textarea.form_control {
  margin-bottom: 63px;
}

.yes_block {
  padding: 13px 39px 0 44px;
}

.notice_block .btn {
  margin-left: 13px;
}

.yes_block .textarea_block {
  margin-top: 17px;
  margin-left: 66px;
}

.form_group.notice_block {
  margin-bottom: 58px;
}

.form_group.medication_block {
  margin-bottom: 46px;
}

.form_group.domestic_block {
  margin-bottom: 52px;
}

.form_group.arrangements_block {
  margin-bottom: 57px;
}

.form_group.appMedication_block {
  margin-bottom: 74px;
}

.search_block {
  padding: 32px 75px 20px 42px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.search_group {
  flex-grow: 1;
  margin-right: 21px;
  margin-top: 8px;
}

.search_group img {
  right: 5px;
}

.search_group .form_control {
  padding-right: 35px;
}

.search_block .btn {
  margin-top: 8px;
}

.active_block {
  padding: 33px 75px 0 42px;
}

.tab_block.diversion_tab .nav_tabs {
  margin: 0 42px;
}

.table_responsive {
  position: relative;
}

.table_custom.pastActivities_table {
  width: 50%;
}

.table_custom.pastActivities_table:before {
  content: "";
  position: absolute;
  top: 0;
  /* top: 81%; */
  left: 0;
  right: 0;
  background: #f2f7ff;
  height: 61.8px;
  border-bottom: 1px solid gray;
}

.table_custom.pastActivities_table th {
  z-index: 1;
  position: relative;
}

table.table_custom.pastActivities_table tr:first-child td {
  padding-top: 21px;
}

table.table_custom.pastActivities_table td {
  border: 0;
  padding: 6px 7px;
}

table.table_custom.pastActivities_table td .btn {
  min-width: 85px;
  height: 29px;
  font-size: 13px;
  padding: 3px 12px;
}

table.table_custom.pastActivities_table td span {
  margin-right: 18px;
}

.diversion_form {
  /* width: 809px; */
  max-width: 100%;
  padding: 23px 42px 81px;
}

.detail_block > .row > div {
  margin-bottom: 30px;
}

.cultural_block {
  padding: 15px 12px;
}

.contact_block {
  padding: 27px 0 0;
}

.contact_block > .row > div {
  margin-bottom: 28px;
}

.address_block {
  padding: 26px 10px 0;
  margin-bottom: 42px;
}

.address_block > .row > div {
  margin-bottom: 24px;
}

.gender_block {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.report_block hr {
  margin: 30px 0;
  background: #a8a8a8;
}

.report_form {
  padding: 38px 42px 25px 42px;
}

.report_form .form_select {
  max-width: 77%;
}

.report_btn {
  padding: 0 115px 47px 42px;
}

.report_btn .btn {
  font-size: 14px;
}

.table_report {
  width: 89%;
}

.table_report:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;
  background: #fdd817;
}

.table_report th {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 30px;
  background: #fdd817;
  z-index: 1;
  position: relative;
}

.table_report td {
  padding: 15px 30px;
}

.table_report tr:first-child td {
  padding-top: 20px;
}

.table_report td:first-child {
  min-width: 110px;
}

.table_report td span {
  display: inline-block;
  min-width: 134px;
  padding: 5px 12px 4px 12px;
}

.table_report tr:nth-child(odd) td span {
  background: #ebebeb;
}

@media (min-width: 992px) {
  #wrapper {
    padding-left: 260px;
  }

  #wrapper.toggled {
    padding-left: 44px;
  }

  #sidebar_wrapper,
  .sidebar_brand,
  .sidebar_nav {
    width: 260px;
  }

  #wrapper.toggled #sidebar_wrapper {
    width: 44px;
  }
}

@media (min-width: 1200px) {
  #wrapper {
    padding-left: 352px;
  }

  #sidebar_wrapper,
  .sidebar_brand,
  .sidebar_nav {
    width: 352px;
  }
}

@media (max-width: 1439px) {
  .person_block {
    padding: 30px 90px 44px 44px;
  }
}

@media (max-width: 1365px) {
  .person_block {
    padding: 30px 30px 44px;
  }

  .yes_block {
    padding: 13px 30px 0;
  }

  .search_block {
    padding: 32px 30px 20px;
  }

  .active_block {
    padding: 33px 30px 0;
  }

  .person_block + .heading {
    padding-left: 30px;
  }

  .no_contact_block .form_control.form_small {
    max-width: 115px;
  }

  .no_contact_block .btn.btn_small,
  .notice_block .btn.btn_small {
    min-width: 115px;
  }

  .notice_block .row > div:last-child {
    padding-left: 0;
  }

  .notice_block .form_select.select_small {
    padding-right: 30px;
  }

  .diversion_form {
    width: 785px;
    padding: 23px 30px 81px;
  }

  .report_form {
    padding: 38px 30px 25px;
  }

  .report_btn {
    padding: 0 30px 47px;
  }

  .report_form .form_select {
    max-width: 100%;
  }

  .tab_block.diversion_tab .nav_tabs {
    margin: 0 30px;
  }

  .table_report td {
    padding: 15px 20px;
  }
}

@media (max-width: 1279px) {
  .no_contact_block {
    margin-left: 15px;
  }

  .no_contact_block .btn {
    margin-left: 10px;
  }

  .notice_block .btn.btn_small {
    margin-left: 10px;
  }

  .table_custom.pastActivities_table {
    width: 70%;
  }
}

@media (max-width: 1199px) {
  .no_contact_block {
    margin-left: 13px;
  }

  .table_report {
    width: 100%;
  }

  .table_report:before {
    display: none;
  }

  .table_report td {
    padding: 15px 10px;
  }
}

@media (max-width: 991px) {
  #wrapper.toggled #sidebar_wrapper,
  .sidebar_brand,
  .sidebar_nav {
    width: 260px;
  }

  .main_header .navbar_brand {
    position: absolute;
    left: 86px;
    z-index: 11111;
  }

  .main_header .navbar_brand i {
    /* background: #000; */
    width: 35px;
    height: 35px;
    border-radius: 50%;
    /* color: #fff; */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #wrapper.toggled .main_header .navbar_brand {
    left: 230px;
  }

  #wrapper.toggled .main_header .navbar_brand i {
    background: #000;
    color: #fff;
  }

  .no_contact_block {
    margin-left: 10px;
  }

  .no_contact_block .btn {
    margin-left: 8px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  #wrapper {
    padding-left: 44px;
  }

  #sidebar_wrapper {
    width: 44px;
  }

  /* #wrapper.toggled {
      padding-left: 260px;
  } */
}

@media (max-width: 767px) {
  .pb_100 {
    padding-bottom: 50px;
  }

  .ml_main {
    margin-left: 0;
  }

  .main_header .navbar_brand {
    left: 12px;
  }

  .btn {
    min-width: auto;
    padding: 9px 12px;
    height: 38px;
  }

  .btn.btn_logOut {
    height: 45px;
    padding: 14px 12px;
  }

  .main-header {
    padding: 15px 0;
  }

  .btn.btn_logOut img {
    margin-left: 8px;
    width: 15px;
  }

  .ml_main.mt_40 {
    margin-top: 30px;
  }

  .tab_block .nav_tabs .nav_link {
    font-size: 14px;
    padding: 9px 9px;
    min-width: auto;
  }

  .table_custom td {
    padding: 7px 7px;
  }

  .table_custom th {
    font-size: 14px;
    font-weight: 600;
    padding: 20px 7px 10px;
  }

  .table_custom tr:first-child td {
    padding-top: 15px;
  }

  .table_custom td img {
    margin-right: 9px;
    max-width: 17px;
  }

  .diversion_block {
    margin: 30px 12px 0 0;
  }

  .sidebar_nav {
    top: 105px;
  }

  .sidebar_brand img {
    width: 120px;
  }

  .tab_block .nav_tabs .nav_item:not(:last-child) {
    margin-right: 0;
  }

  .person_block {
    padding: 10px 15px 20px;
  }

  .yes_block {
    padding: 10px 15px 0;
  }

  .person_block + .heading {
    padding-left: 15px;
  }

  .form_control,
  .form_select {
    padding: 9px 12px;
  }

  .form_group,
  .clientDetails_block .personalBelongings_block .form_group,
  .form_group.notice_block,
  .form_group.medication_block,
  .form_group.domestic_block,
  .form_group.arrangements_block {
    margin-bottom: 16px;
  }

  .form_check_input[type="checkbox"],
  .radio_custom .form_check_input[type="radio"] {
    width: 17px;
    height: 17px;
  }

  .form_check {
    padding-left: 23px;
  }

  .form_check .form_check_input {
    margin-left: -23px;
  }

  .form_check_input:checked[type="checkbox"],
  .radio_custom .form_check_input:checked[type="radio"] {
    background-size: 10px auto;
  }

  label.form_check_label,
  .form_control,
  .form_select {
    margin-top: 3px;
  }

  .heading {
    padding: 12px 15px;
  }

  .btn.btn_small,
  .no_contact_block .btn.btn_small,
  .notice_block .btn.btn_small {
    min-width: auto;
    padding: 9px 15px;
  }

  .clientWellbeing_block {
    padding: 10px 15px 20px;
  }

  .table_noBg td {
    padding: 7px 5px;
  }

  .table_noBg th {
    padding: 7px 5px;
  }

  .clientWellbeing_block .table_responsive,
  .clientWellbeing_block textarea.form_control {
    margin-bottom: 15px;
  }

  .clientWellbeing_block .btn {
    margin-bottom: 16px;
  }

  .clientWellbeing_block h6 {
    margin-bottom: 8px;
  }

  .clientDetails_block {
    padding: 17px 15px 0;
  }

  .clientDetails_block .form_group {
    margin-bottom: 20px;
  }

  .personalBelongings_block label:not(.form_check_label) {
    font-size: 16px;
    margin-bottom: 0;
  }

  .checkbox_mrg .form_check:not(:last-child) {
    margin-bottom: 2px;
  }

  .personalBelongings_block textarea.form_control,
  .form_group.appMedication_block {
    margin-bottom: 30px;
  }

  .notice_block .row > div:last-child {
    padding-left: 12px;
  }

  .person_block .mt_13,
  .yes_block .textarea_block {
    margin-top: 3px;
  }

  .yes_block .textarea_block {
    margin-left: 62px;
  }

  .search_block {
    padding: 12px 15px 20px;
  }

  .active_block {
    padding: 16px 15px 0;
  }

  .table_custom.pastActivities_table {
    width: 100%;
  }

  .table_custom.pastActivities_table:before {
    display: none;
  }

  .diversion_form {
    padding: 20px 15px 40px;
  }

  .diversion_form .mb_30 {
    margin-bottom: 25px;
  }

  .contact_block {
    padding: 20px 0 4px;
  }

  .cultural_block,
  .address_block {
    padding: 15px 10px;
  }

  .address_block {
    padding: 20px 10px 2px;
    margin-bottom: 27px;
  }

  .detail_block > .row > div,
  .contact_block > .row > div,
  .address_block > .row > div {
    margin-bottom: 16px;
  }

  .tab_block.diversion_tab .nav_tabs {
    margin: 0 15px;
  }

  .detail_block {
    margin-bottom: 4px;
  }

  .report_form {
    padding: 20px 15px 0;
  }

  .report_form .mb_30 {
    margin-bottom: 16px;
  }

  .report_block hr {
    margin: 20px 0;
  }

  .report_btn {
    padding: 0 15px 30px;
  }

  .table_report th {
    padding: 7px 5px;
  }

  .table_report td {
    padding: 7px 5px;
  }

  .table_report tr:first-child td {
    padding-top: 10px;
  }

  .table_report td span {
    min-width: 70px;
    padding: 3px 5px;
  }
}

@media (max-width: 575px) {
  .tab_block .nav_tabs {
    margin: 0 6px;
  }

  .form_check_inline {
    margin-right: 8px;
  }

  .no_contact_block .form_control.form_small {
    max-width: 109px;
  }

  .heading h5,
  .clientWellbeing_block h6 {
    text-transform: none;
  }

  .yes_block .textarea_block {
    margin-left: 54px;
  }

  .notice_block .btn.btn_small {
    margin-left: 7px;
  }

  .gender_block {
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 0 !important;
  }

  .gender_block > div:first-child {
    margin-bottom: 16px;
  }

  .tab_block.diversion_tab .nav_tabs {
    margin: 0 0;
  }

  .clientWellbeing_block table td span {
    margin-left: 12px;
  }

  table.table_custom.pastActivities_table td span {
    margin-right: 12px;
  }

  .detail_block > .row > div:first-child {
    margin-bottom: 0;
  }
}

@media (max-width: 350px) {
  .tab_block .nav_tabs .nav_link {
    font-size: 13px;
    padding: 9px 7px;
  }

  .report_btn .btn {
    padding: 9px 10px;
  }
}
