@import "../../assets/styles/variables.scss";
.report-form{
    margin-top: 1.2378516624040925rem; 
    .form-label {
        font-family: Tahoma !important;
        color: $black;
        font-weight: 700;
        float: right;
        padding: 0.2864450127877238rem 0.8184143222506394rem 0 0;
        font-size: 0.6547314578005116rem;

        &.text-label {
            padding-left: 80px;

            @media (min-width:992px) and (max-width: 1200px) {
                padding-left: 30px;
            }
        }

    }
    h4{
        font-family: Tahoma !important;
        color: $black;
        font-weight: 700;
        font-size: 1.5728900255754475rem;
    }
    .report-section{
        box-shadow: 1px 3px 11px $shadow-color;
        padding: 1.2736572890025575rem 2.0460358056265986rem !important;
        .report-btn{
            font-size: 0.7365728900255755rem;
            font-weight: 800;
            font-family: Tahoma !important;
        }
    }
    .form-field-border{
        border-radius: 0;
        border: 1px solid $input-grey;
        color: $black;
        font-weight: 400;
        font-size: 0.6138107416879796rem;
    }
}