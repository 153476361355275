@import "../../assets/styles/variables.scss";

.diversion-centre-section {
    .tabs-section {
        .nav-link {
            span {
                padding: 0 1.227621483375959rem;
            }

            &:focus,
            &:hover,
            &.active {
                border: 0;

                span {
                    background: url('../../assets/images/tab_active_mid.png') repeat transparent;
                    background-size: 100% 75px;
                    padding: 0 1.227621483375959rem;
                    color: #ffffff;
                }
            }
        }

        .tab-content {
            .tab-table {
                .table {
                    thead {
                        th {
                            font-size: 0.5728900255754475rem;
                        }
                    }

                    tbody {
                        .checkbox-sec {
                            input[type='radio'].custom-checkbox {
                                &:not(:checked) {
                                    position: absolute;
                                    left: -9999px;

                                    + {
                                        label {
                                            position: relative;
                                            padding-left: 1.95em;
                                            cursor: pointer;
                                            padding-top: 20px;
                                            color: $black;
                                            font-weight: 400;
                                            font-family: Tahoma !important;
                                            font-size: 0.6547314578005116rem;
                                            float: left;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                right: 0;
                                                top: 0px;
                                                width: 1.25em;
                                                height: 1.25em;
                                                border-radius: 25px;
                                                border: 2px solid $orange;
                                                background: $white;
                                                border-radius: none;
                                                box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                            }

                                            &:after {
                                                content: '\2713\0020';
                                                position: absolute;
                                                top: .15em;
                                                left: .22em;
                                                font-size: 1.3em;
                                                color: $white;
                                                background: $orange;
                                                border-radius: 25px;
                                                border: 2px solid $orange;
                                                line-height: 0.8;
                                                color: #09ad7e;
                                                transition: all .2s;
                                                opacity: 0;
                                                transform: scale(0);
                                            }
                                        }
                                    }
                                }

                                &:checked {
                                    position: absolute;
                                    left: -9999px;

                                    + {
                                        label {
                                            position: relative;
                                            padding-left: 1.95em;
                                            cursor: pointer;
                                            padding-top: 20px;
                                            color: $black;
                                            font-weight: 400;
                                            font-family: Tahoma !important;
                                            font-size: 0.6547314578005116rem;
                                            float: left;

                                            &:before {
                                                content: '';
                                                position: absolute;
                                                right: 0;
                                                top: 0px;
                                                // width: 1.25em;
                                                border-radius: 25px;
                                                // height: 1.25em;
                                                border: 2px solid $orange;
                                                background: $white;
                                                border-radius: none;
                                                box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                            }

                                            &:after {
                                                content: '\2713\0020';
                                                position: absolute;
                                                right: 0;
                                                top: 0px;
                                                line-height: 19px;
                                                font-size: 25px;
                                                color: $white;
                                                background: $orange;
                                                border-radius: 25px;
                                                border: 3px solid $orange;
                                                transition: all .2s;
                                                opacity: 1;
                                                transform: scale(1);

                                                @media (max-width: 1200px) and (min-width: 1130px) {
                                                    line-height: 17px;
                                                    left: 0px;
                                                    font-size: 20px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        td {
                            font-size: 0.5319693094629157rem !important;
                            border-right: 3px solid $white;
                            padding: 0.20460358056265984rem;
                        }
                    }
                }
            }

            .comunity-user {
                .Main-search {
                    .MuiFormControl-root {
                        width: 79%;

                        @media (max-width: 1200px) and (min-width: 1130px) {
                            width: 72%;
                        }
                    }
                }

                .no-border {
                    border: 0;

                    .css-93ilmd-DropDown {
                        top: 40px;
                        left: -3px;
                        width: 180px;
                        text-align: left;
                    }

                    .react-dropdown-select-dropdown {
                        text-align: left;
                        top: 40px;
                        left: -3px;
                        color: $black;
                        width: 160px;

                        .css-148o527-ItemComponent.react-dropdown-select-item-selected {
                            background: $orange;
                        }
                    }

                    .css-1yc4zyy-DropdownHandleComponent {
                        display: none;

                        .css-1aarvou-DropdownHandleComponent {
                            svg {
                                display: none !important;
                            }
                        }
                    }

                    .css-1hdte7r-InputComponent {
                        font-size: inherit !important;
                    }

                    &.arrow-down {
                        background: url('../../assets/images/down-arrow.svg') no-repeat;
                        background-position: 97% 11px;
                        background-size: 20px 20px;
                        color: $black;

                        @media (min-width:992px) and (max-width: 1200px) {
                            background-position: 95% 12px;
                        }

                        @media (max-width: 767px) {
                            background-position: 100% 13px;
                            background-size: 29px 7px;
                        }
                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &:focus-within {
                        box-shadow: none;
                    }

                    &:hover {
                        box-shadow: none;
                    }
                }

                .new-community {
                    .text-label {
                        font-family: Tahoma !important;
                        color: $black;
                        font-weight: 700;
                        font-size: 0.5728900255754475rem;
                    }

                    input[type='radio'].custom-checkbox {
                        &:not(:checked) {
                            position: absolute;
                            left: -9999px;

                            + {
                                label {
                                    position: relative;
                                    padding-left: 1.95em;
                                    cursor: pointer;
                                    padding-top: 20px;
                                    color: $black;
                                    font-weight: 400;
                                    font-family: Tahoma !important;
                                    font-size: 0.6138107416879796rem;
                                    float: left;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 25px;
                                        width: 1.25em;
                                        height: 1.25em;
                                        border: 1px solid $black;
                                        background: $white;
                                        border-radius: none;
                                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                    }

                                    &:after {
                                        content: '\2713\0020';
                                        position: absolute;
                                        top: .15em;
                                        left: .22em;
                                        font-size: 1.3em;
                                        line-height: 0.8;
                                        color: #09ad7e;
                                        transition: all .2s;
                                        opacity: 0;
                                        transform: scale(0);
                                    }
                                }
                            }
                        }

                        &:checked {
                            position: absolute;
                            left: -9999px;

                            + {
                                label {
                                    position: relative;
                                    padding-left: 1.95em;
                                    cursor: pointer;
                                    padding-top: 20px;
                                    color: $black;
                                    font-weight: 400;
                                    font-family: Tahoma !important;
                                    font-size: 0.6138107416879796rem;
                                    float: left;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 25px;
                                        // width: 1.25em;
                                        // height: 1.25em;
                                        border: 1px solid $black;
                                        background: $white;
                                        border-radius: none;
                                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                    }

                                    &:after {
                                        content: '\2713\0020';
                                        position: absolute;
                                        top: 24px;
                                        line-height: 19px;
                                        left: 0px;
                                        font-size: 23px;
                                        color: $orange;
                                        border: 3px solid $orange;
                                        transition: all .2s;
                                        opacity: 1;
                                        transform: scale(1);

                                        @media (max-width: 1200px) and (min-width: 1130px) {
                                            line-height: 17px;
                                            left: 0px;
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    textarea.form-control {
                        border-radius: 0;
                    }

                    .btn-sec {
                        .btn {
                            &.main-btn {
                                margin: 0;
                                width: 100%;
                            }
                        }
                    }

                    .form-fields {
                        &.form-control {
                            border-radius: 0;
                            transition: none;

                            &::placeholder {
                                text-align: left !important;
                            }
                        }
                    }

                    .selection-select {
                        position: relative;
                        box-shadow: none;

                        &.css-wmw4vi-ReactDropdownSelect {
                            border: 1px solid $input-grey;
                            font-size: 0.6138107416879796rem;
                            background: $white;
                            padding: 0.5092071611253197rem 0.4092071611253197rem;

                            &.font-bold {
                                border: 1px solid $black !important;

                                .css-3tj17m-InputComponent {
                                    font-weight: 700 !important;
                                }
                            }

                            @media (max-width: 1440px) {
                                width: 100%;
                            }

                            .css-1yc4zyy-DropdownHandleComponent {
                                display: none;

                                .css-1aarvou-DropdownHandleComponent {
                                    svg {
                                        display: none !important;
                                    }
                                }
                            }

                            .css-1g6qqqc-DropDown {
                                top: 47.75px;
                            }

                            .css-avzylv-DropDown {
                                top: 43.75px;
                            }
                        }

                        &.arrow-down {
                            background: url('../../assets/images/down-arrow.svg') no-repeat;
                            background-position: 97% 15px;
                            background-size: 20px 20px;
                            background-color: #fff;

                            &.observation-input {
                                background: url('../../assets/images/black-arrow.svg') no-repeat $bg-grey;
                                background-position: 95% 10px;
                                background-size: 20px 20px;

                                @media (min-width:992px) and (max-width: 1200px) {
                                    background-size: 14px 20px;
                                }
                            }

                            @media (min-width:992px) and (max-width: 1200px) {
                                background-position: 95% 12px;
                            }

                            @media (max-width: 767px) {
                                background-position: 100% 13px;
                                background-size: 29px 7px;
                            }
                        }

                        .css-148o527-ItemComponent {
                            &.react-dropdown-select-item-selected {
                                background: $orange;
                                color: $white;
                            }
                        }
                    }

                    .react-dropdown-select-dropdown {
                        top: 50px;
                        width: 390px;

                        @media (min-width:992px) and (max-width: 1200px) {
                            width: 260px;
                        }
                    }

                    .client-record {
                        padding: 0.8184143222506394rem 0;

                        h6 {
                            font-size: 0.6547314578005116rem;
                            font-family: Tahoma !important;
                            color: $black;
                            font-weight: 700;
                        }

                        p {

                            font-weight: 400;
                            color: $black;
                            font-family: Tahoma !important;
                            font-size: 0.5728900255754475rem;

                            strong {
                                font-family: Tahoma !important;
                                color: $black;
                                font-weight: 700;
                            }
                        }

                        .btn-sec {
                            .default-btn {
                                &.btn-width {
                                    width: 7.2378516624040925rem !important;
                                }

                                background-color: $orange;
                                width:5.2378516624040925rem;
                                border-color: $orange;

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }
                    }

                    &.diversion {
                        .btn-sec {
                            .default-btn {
                                &.btn-width {
                                    width: 7.2378516624040925rem !important;
                                }

                                background-color: $hover-field;
                                width:6.956521739130435rem;
                                border-color: $hover-field;

                                &:focus {
                                    box-shadow: none;
                                }
                            }
                        }

                        .observation-label {
                            font-weight: 700;
                            color: $black;
                            font-size: 0.49104859335038364rem;
                            font-family: Tahoma !important;
                            padding: 0.4092071611253197rem 0.8184143222506394rem;
                            margin-bottom: 0;
                            box-shadow: 0px 4px 10px 3px $shadow-color;
                            height: 2.164450127877238rem;
                            display: flex;
                            align-items: center;

                            @media (min-width:992px) and (max-width: 1200px) {
                                font-size: 0.39104859335038364rem;
                                height: 2.564450127877238rem;
                            }
                        }

                        .observation-input {
                            height: 1.460358056265986rem;
                            background: $bg-grey;
                            border: 0;

                            &::placeholder {
                                font-size: 0.49104859335038364rem !important;
                                text-align: left;
                                color: $black;
                            }
                        }

                        .observation-colum {
                            width: 9.002557544757034rem;
                        }
                    }
                }

            }
        }
    }
}

.form-consent {
    .modal-dialog {
        max-width: 40.92071611253197rem;

        .modal-content {
            margin-top: 5.184143222506394rem;
            border-radius: 0;

            .modal-body {
                padding: 1.227621483375959rem 1.227621483375959rem;

                .form-consent-heading {
                    .text-label {
                        font-family: Tahoma !important;
                        color: $black;
                        font-weight: 700;
                        font-size: 0.9138107416879796rem;

                        small {
                            font-size: 0.6138107416879796rem;
                            font-weight: bolder;
                        }
                    }
                }

                input[type='checkbox'].custom-checkbox,
                input[type='radio'].custom-checkbox {
                    &:not(:checked) {
                        position: absolute;
                        left: -9999px;

                        + {
                            label {
                                position: relative;
                                padding-left: 1.95em;
                                cursor: pointer;
                                padding-top: 20px;
                                color: $black;
                                font-weight: 400;
                                font-family: Tahoma !important;
                                font-size: 0.6138107416879796rem;
                                float: left;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 25px;
                                    width: 1.25em;
                                    height: 1.25em;
                                    border: 1px solid $black;
                                    background: $white;
                                    border-radius: none;
                                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                }

                                &:after {
                                    content: '\2713\0020';
                                    position: absolute;
                                    top: .15em;
                                    left: .22em;
                                    font-size: 1.3em;
                                    line-height: 0.8;
                                    color: #09ad7e;
                                    transition: all .2s;
                                    opacity: 0;
                                    transform: scale(0);
                                }
                            }
                        }
                    }

                    &:checked {
                        position: absolute;
                        left: -9999px;

                        + {
                            label {
                                position: relative;
                                padding-left: 1.95em;
                                cursor: pointer;
                                padding-top: 20px;
                                color: $black;
                                font-weight: 400;
                                font-family: Tahoma !important;
                                font-size: 0.6138107416879796rem;
                                float: left;

                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 25px;
                                    // width: 1.25em;
                                    // height: 1.25em;
                                    border: 1px solid $black;
                                    background: $white;
                                    border-radius: none;
                                    box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                }

                                &:after {
                                    content: '\2713\0020';
                                    position: absolute;
                                    top: 24px;
                                    line-height: 19px;
                                    left: 0px;
                                    font-size: 23px;
                                    color: $orange;
                                    border: 3px solid $orange;
                                    transition: all .2s;
                                    opacity: 1;
                                    transform: scale(1);

                                    @media (max-width: 1200px) and (min-width: 1130px) {
                                        line-height: 17px;
                                        left: 0px;
                                        font-size: 20px;
                                    }
                                }
                            }
                        }
                    }
                }

                .selection-select {
                    position: relative;
                    box-shadow: none;

                    &.css-wmw4vi-ReactDropdownSelect {
                        border: 1px solid $input-grey;
                        font-size: 0.6138107416879796rem;
                        background: $white;
                        padding: 0.5092071611253197rem 0.4092071611253197rem;

                        &.font-bold {
                            border: 1px solid $black !important;

                            .css-3tj17m-InputComponent {
                                font-weight: 700 !important;
                            }
                        }

                        @media (max-width: 1440px) {
                            width: 100%;
                        }

                        .css-1yc4zyy-DropdownHandleComponent {
                            display: none;

                            .css-1aarvou-DropdownHandleComponent {
                                svg {
                                    display: none !important;
                                }
                            }
                        }

                        .css-1g6qqqc-DropDown {
                            top: 47.75px;
                        }

                        .css-avzylv-DropDown {
                            top: 43.75px;
                        }
                    }

                    &.arrow-down {
                        background: url('../../assets/images/down-arrow.svg') no-repeat;
                        background-position: 97% 15px;
                        background-size: 20px 20px;
                        background-color: #fff;
                    }

                    .react-dropdown-select-dropdown {
                        top: 50px;
                        width: 330px;

                        @media (min-width:992px) and (max-width: 1200px) {
                            width: 280px;
                        }
                    }

                    .css-148o527-ItemComponent {
                        &.react-dropdown-select-item-selected {
                            background: $orange;
                            color: $white;
                        }
                    }
                }

                .form-consent-body {
                    &.form-textarea-padding {
                        padding-left: 1.432225063938619rem;

                        textarea.form-control {
                            border-radius: 0;
                        }
                    }

                    .form-para {
                        font-family: Tahoma !important;
                        color: $black;
                        font-weight: 400;
                        font-size: 0.6138107416879796rem;
                        margin-bottom: 0.6138107416879796rem;
                        padding-top: 0.8184143222506394rem;
                    }

                    .form-fields {
                        &.form-control {
                            border-radius: 0;
                            transition: none;

                            &::placeholder {
                                text-align: left !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

.detail-form-consent {
    textarea {
        &.form-control {
            border-radius: 0 !important;
        }
    }
}

.select-form-consent {
    .MuiInputBase-formControl {
        font-size: 0.59104859335038364rem !important;

        .MuiAutocomplete-endAdornment {
            top: 6px !important;
        }
    }
}

.MuiAutocomplete-paper {
    font-size: 15px !important;
}