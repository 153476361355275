@import "../../assets/styles/variables.scss";
body{
    font-family: Tahoma !important;
}

.client-form{
    padding: 0.8184143222506394rem 1.6368286445012787rem;
    box-shadow: -1px 2px 20px $shadow-color;
    .row{
        .col-lg-3{
            &.pl-3{   
                @media (min-width:993px) and (max-width:1200px) {
                    padding-left: 0 !important;
                }
            }
            &.pl-5{
                @media (min-width:993px) and (max-width:1200px) {
                    padding-left: 0 !important;
                }
            }
        }
        .col-lg-2{
            &.pl-4{
                @media (min-width:993px) and (max-width:1200px) {
                    padding-left: 0 !important;
                }
            }
            &.pl-5{
                @media (min-width:993px) and (max-width:1200px) {
                    padding-left: 0 !important;
                }
            }
        }
        .col-md-2{
            &.pl-4{
                @media (min-width:993px) and (max-width:1200px) {
                    padding-left: 0 !important;
                }
            }
        }
        &.pl-3{
            @media  (max-width:992px) {
                padding-left: 0 !important;
            }
        }
        @media  (max-width:992px) {
            padding-left: 0 !important;
        }
        .pl-5{
            @media  (max-width:992px) {
                padding-left: 0 !important;
            }
        }
    }
    strong{
        font-family: Tahoma !important;
        color: $black;
        font-weight: 700;
        font-size: 0.6547314578005116rem;
        @media (min-width:992px) and (max-width:1200px) {
            font-size: 0.49104859335038364rem;
        }
    }
    .margin-20{
        margin-top: -0.4092071611253197rem;
    }
    .form-label{
        float: left;
        margin-right: 20px;
        @media (min-width:992px) and (max-width:1200px) {
            margin-right: 23px;
        }
    }
    .form-fields.form-control{
        border: 0;
        border-radius: 0;
        &:focus{
            box-shadow: none;
        }
    }
    .form-field-border{
        border-radius: 0;
        border: 1px solid $input-grey;
        color: $black;
        font-weight: 400;
        font-size: 0.6138107416879796rem;
        &:focus{
            box-shadow: none;
        }
    }
    .custom-width{
        width: 230px;
        @media (min-width:1300px) and (max-width:1560px) {
            width: 230px;
        }
        @media (min-width:992px) and (max-width:1299px) {
            width: 260px;
        }
        @media (min-width:768px) and (max-width:991px) {
            width: 180px;
        }
        @media (max-width:767px) {
            width: 100%;
        }
    }
    select.form-field-border{
        appearance: none;
    }
    input[type='radio'].custom-checkbox,input[type='checkbox'].custom-checkbox {
        &:not(:checked) {
            position: absolute;
            left: -9999px;
            + {
                label {
                    position: relative;
                    padding-left: 1.95em;
                    cursor: pointer;
                    padding-top: 20px;
                    color: $black;
                    font-weight: 400;
                    font-family: Tahoma !important;
                    font-size: 0.49104859335038364rem;
                    float: left;
                    @media (min-width:992px) and (max-width:1200px) {
                        font-size: 0.49104859335038364rem;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 25px;
                        width: 1.25em;
                        height: 1.25em;
                        border: 1px solid $black;
                        background: $white;
                        border-radius: none;
                        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: .15em;
                        left: .22em;
                        font-size: 1.3em;
                        line-height: 0.8;
                        color: #09ad7e;
                        transition: all .2s;
                        opacity: 0;
                        transform: scale(0);
                    }
                }
            }
        }
        &:checked {
            position: absolute;
            left: -9999px;
            + {
                label {
                    position: relative;
                    padding-left: 1.95em;
                    cursor: pointer;
                    padding-top: 20px;
                    color: $black;
                    font-weight: 400;
                    font-family: Tahoma !important;
                    font-size: 0.49104859335038364rem;
                    float: left;
                    @media (min-width:992px) and (max-width:1200px) {
                        font-size: 0.49104859335038364rem;
                    }
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 25px;
                        // width: 1.25em;
                        // height: 1.25em;
                        border: 1px solid $black;
                        background: $white;
                        border-radius: none;
                        box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
                    }
                    &:after {
                        content: '\2713\0020';
                        position: absolute;
                        top: 24px;
                        line-height: 19px;
                        left: 0px;
                        font-size: 23px;
                        color: $orange;
                        border: 3px solid $orange;
                        transition: all .2s;
                        opacity: 1;
                        transform: scale(1);
                        @media (max-width: 1200px) and (min-width: 1130px){
                            line-height: 17px;
                            left: 0px;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
    .divider{
        border-right: 1px solid $input-grey;
        padding: 1.3094629156010231rem 0;
        @media (max-width:992px) {
           display: none;
        }
    }
    .search-address{
        position: relative;
        .search-icon{
            background: url('../../assets/images/search.svg') no-repeat;
            background-position: 95% 15px;
            background-size: 20px 20px;
            background-color: $white;
            @media (min-width:992px) and (max-width:1200px) {
                background-position: 95% 11px;
            }
            @media (max-width:768px) {
                right: 10px;
                top: 40px;
             }
        }
    }
    .select-field{
        position: relative;
        background-color: $white;
        .arrow-down{
            background: url('../../assets/images/down-arrow.svg') no-repeat;
            background-position: 95% 15px;
            background-size: 20px 20px;
            @media (min-width:992px) and (max-width:1200px) {
                background-position: 95% 11px;
            }
        }
    }
}
.form-btn{
    .btn.main-btn{
        &.save-btn{
            width: 5.2378516624040925rem;    
        }
        width: 8.593350383631714rem;
        border-radius: 0.4092071611253197rem;
        @media (max-width:767px) {
            width: 100%;
            }
    }
}
.bg-grey{
    background-color: $bg-grey;
}
.form-fields::placeholder {
    // text-align: center !important;
}
.form-fields::placeholder,
.form-field-border::placeholder{
    font-size: 0.6138107416879796rem;
    font-family: Tahoma !important;
    color: $black;
    font-weight: 400;
}
::-webkit-calendar-picker-indicator {
    filter: invert(0.8);
    cursor: pointer;
}
.full-width{
    float: left;
    width: 100%;
}
    .location-search-input{
        &input[type=text]{
            font-size: 0.59104859335038364rem !important;
            color: #000;
            font-weight: 400 !important;
        }
    }
.autocomplete-dropdown-container{
    font-size: 0.59104859335038364rem !important;
    color: #000;
    font-weight: 400 !important;
}