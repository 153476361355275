@import "../../../assets/styles/variables.scss";
body{
    font-family: Tahoma !important;
    background-color: #fff !important;
}
.dashboard-header {
    background-color: $white;
    position: relative;
    height: 4.051150895140665rem;
    box-shadow: 0px 4px 15px $shadow-color;
    .navigation-left{
        .MuiButton-root{
            cursor: pointer !important;
        }
        img{
            width: 7.406649616368286rem;
            margin-left: 0.8184143222506394rem;
        }
    }
    .admin-detail{
        text-align: right;
        padding: 0.4092071611253197rem 0.8184143222506394rem 0 2.0460358056265986rem;
        align-items: center;
        img{
            width: 1.0913554987212277rem;
            margin-right: 10px;
        }
        h6{
            display: inline-block;
            margin-bottom: 0;
            font-size: 0.6547314578005116rem;
        }
    }
    @media (max-width:992px) {
        .col-md-7{
            display: none;
        }
      }
}
.MuiBackdrop-root{
    background-color: transparent !important;
}
.MuiDrawer-paper{
    overflow-y: hidden !important;
    background-color: $darken-blue !important;
    box-shadow: none !important;
    .MuiListItem-root{
        border-bottom: 1px solid $blue-text !important;
        color: $blue-text !important;
        .MuiListItemIcon-root{
            color: $blue-text !important;
            min-width: 48px;
            &:hover{
                text-decoration: none !important;
                background-color: transparent !important;
            }
            &:focus{
               outline: none !important;
            }
        }
        .MuiTypography-body1{
            font-family: 'Tahoma' !important;
            font-size: 0.6547314578005116rem !important;
            &:hover{
                color: $white !important;
            }
        }
        &:hover{
            // background-color: $orange  !important;
            background-color: #d1242a  !important;
            color: $white  !important;
            .MuiListItemIcon-root{
                color: $white !important;
               
            }
        }
    }
    .MuiList-padding{
        padding-top: 0 !important;
    }
}

.MuiSvgIcon-root, .MuiButton-root{
    width: 1.227621483375959rem !important;
    &:focus{
        outline: none !important;
    }
}
.MuiAppBar-colorPrimary{
    box-shadow: -1px -1px 15px $shadow-color !important;
    color: $black !important;
    background: $white !important;
    img{
        width: 7.406649616368286rem;
    }
    .MuiToolbar-root{
        padding-top: 20px;
    }
    .header-logout{
        display: inline-block;
        width: auto !important;
        float: right;
        margin-bottom: 0;
        font-size: 0.6547314578005116rem;
        transition: transparent;
        min-width: auto;
        padding: 0px;
        &:hover{
            background-color: transparent;
            transition: transparent;
        }
        .MuiButton-label{
            &:focus{
                outline: none;
            }
        }
        .MuiTouchRipple-root{
            &:focus{
                transition: transparent;
                outline: none;
            }
        }
    }
}

/* Handles the position of the Left Bar and the associated height for the header */
.MuiAppBar-positionFixed{
    height: 20%;

    @media (min-height: 560px) {
        height: 112px !important;
    }
}
.MuiDrawer-paperAnchorDockedLeft{
    overflow-y: auto !important;
    border-right: none !important;
    z-index: 0!important;
    top: 20% !important;
    height: 80% !important;

    @media (min-height: 560px) {
        top: 112px !important;
        height: calc(100% - 112px) !important;
    }

    .makeStyles-toolbar-9{
        min-height: 4.051150895140665rem;
        background: $white !important;
        padding-top: 20px;
    }
}

/* End menu height controls */

.MuiMenu-paper{
    top: 75px !important;
    right: 45px !important;
    left: auto !important;
    .MuiMenuItem-root{
        font-size: 0.6547314578005116rem;
        color: $black;
        &.MuiListItem-button{
            &:hover{
                // background-color: $orange;
                background-color: #d1242a;
                color: $white;
            }    
        }
    }
}

.form-control{
    font-size: 0.59104859335038364rem !important;
}
.MuiOutlinedInput-input{
    font-size: 0.59104859335038364rem !important;
}
.MuiInputLabel-formControl{
    font-size: 0.59104859335038364rem !important;
}
