@import "../../assets/styles/variables.scss";

.contact-section {
    .card {
        box-shadow: -1px 2px 20px $shadow-color;
        border-radius: 0;
        border: 0;

        .card-header {
            font-family: Tahoma !important;
            color: $black;
            font-weight: 700;
            font-size: 0.6547314578005116rem;
            background-color: $white;
            border-bottom: 0;
        }

        .card-body {
            padding-top: 0.1184143222506394rem;

            form {
                .form-label {
                    font-family: Tahoma !important;
                    color: $black;
                    font-weight: 700;
                    float: left;
                    padding: 0.2864450127877238rem 0.8184143222506394rem 0 0;
                    font-size: 0.6138107416879796rem;

                    &.text-label {
                        padding-left: 80px;

                        @media (min-width:992px) and (max-width: 1200px) {
                            padding-left: 30px;
                        }
                    }

                }

                .form-field-border {
                    border-radius: 0;
                    color: $form-field;
                    font-weight: 400;
                    border-color: $input-grey;
                    font-size: 0.6138107416879796rem;

                    &:focus {
                        box-shadow: none;
                    }

                    &.custom-width {
                        @media (max-width: 1440px) {
                            width: 100%;
                        }
                    }
                }

                .select-field {
                    position: relative;

                    select {
                        appearance: none;

                        &.arrow-down {
                            background: url('../../assets/images/down-arrow.svg') no-repeat;
                            background-position: 97% 15px;
                            background-size: 20px 20px;
                            background-color: $white;

                            @media (min-width:992px) and (max-width: 1200px) {
                                background-position: 95% 12px;
                            }

                            @media (max-width: 767px) {
                                background-position: 100% 7px;
                                background-size: 30px 10px;
                            }
                        }
                    }
                }

                .selection-select {
                    position: relative;

                    &.css-wmw4vi-ReactDropdownSelect {
                        border: 1px solid $input-grey;
                        font-size: 0.6138107416879796rem;
                        background: $white;
                        padding: 0.5092071611253197rem 0.4092071611253197rem;

                        @media (max-width: 1440px) {
                            width: 100%;
                        }

                        .css-1yc4zyy-DropdownHandleComponent {
                            display: none;

                            .css-1aarvou-DropdownHandleComponent {
                                svg {
                                    display: none !important;
                                }
                            }
                        }

                        .css-1g6qqqc-DropDown {
                            top: 47.75px;
                        }

                        .css-avzylv-DropDown {
                            top: 43.75px;
                        }
                    }

                    &.arrow-down {
                        background: url('../../assets/images/down-arrow.svg') no-repeat;
                        background-position: 97% 15px;
                        background-size: 20px 20px;
                        background-color: #fff;

                        @media (min-width:992px) and (max-width: 1200px) {
                            background-position: 95% 12px;
                        }

                        @media (max-width: 767px) {
                            background-position: 100% 13px;
                            background-size: 29px 7px;
                        }
                    }

                    .css-148o527-ItemComponent {
                        &.react-dropdown-select-item-selected {
                            background: $orange;
                            color: $white;
                        }
                    }
                }
                button.main-btn.default-btn.btn.btn-primary.ali {
                    width: 7.242966751918159rem;
                    font-weight: 700;
                    font-size: 0.6547314578005116rem;
                    padding: 0.7184143222506394rem 1.864450127877238rem;
                    margin-top: 0;
                }
                .default-btn {
                    width: 5.2378516624040925rem;

                    @media (max-width: 767px) {
                        width: 100%;
                    }
                }

                .checkbox-sec {
                    float: right;

                    @media (max-width: 767px) {
                        float: none;
                    }

                    input[type='checkbox'].custom-checkbox {
                        &:not(:checked) {
                            position: absolute;
                            left: -9999px;

                            + {
                                label {
                                    position: relative;
                                    padding-left: 1.95em;
                                    cursor: pointer;
                                    padding-top: 20px;
                                    color: $black;
                                    font-weight: 400;
                                    font-family: Tahoma !important;
                                    font-size: 0.6547314578005116rem;
                                    float: left;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 25px;
                                        width: 1.25em;
                                        height: 1.25em;
                                        border-radius: 25px;
                                        border: 2px solid $orange;
                                        background: $white;
                                        border-radius: none;
                                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                    }

                                    &:after {
                                        content: '\2713\0020';
                                        position: absolute;
                                        top: .15em;
                                        left: .22em;
                                        font-size: 1.3em;
                                        color: $white;
                                        background: $orange;
                                        border-radius: 25px;
                                        border: 2px solid $orange;
                                        line-height: 0.8;
                                        color: #09ad7e;
                                        transition: all .2s;
                                        opacity: 0;
                                        transform: scale(0);
                                    }
                                }
                            }
                        }

                        &:checked {
                            position: absolute;
                            left: -9999px;

                            + {
                                label {
                                    position: relative;
                                    padding-left: 1.95em;
                                    cursor: pointer;
                                    padding-top: 20px;
                                    color: $black;
                                    font-weight: 400;
                                    font-family: Tahoma !important;
                                    font-size: 0.6547314578005116rem;
                                    float: left;

                                    &:before {
                                        content: '';
                                        position: absolute;
                                        left: 0;
                                        top: 25px;
                                        // width: 1.25em;
                                        border-radius: 25px;
                                        // height: 1.25em;
                                        border: 2px solid $orange;
                                        background: $white;
                                        border-radius: none;
                                        box-shadow: inset 0 1px 3px rgba(0, 0, 0, .1);
                                    }

                                    &:after {
                                        content: '\2713\0020';
                                        position: absolute;
                                        top: 24px;
                                        line-height: 19px;
                                        left: 0px;
                                        font-size: 23px;
                                        color: $white;
                                        background: $orange;
                                        border-radius: 25px;
                                        border: 3px solid $orange;
                                        transition: all .2s;
                                        opacity: 1;
                                        transform: scale(1);

                                        @media (max-width: 1200px) and (min-width: 1130px) {
                                            line-height: 17px;
                                            left: 0px;
                                            font-size: 20px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .table {
        thead {
            th {
                font-size: 0.5728900255754475rem;
                border-right: 3px solid $white;
                width: 11.743427109974425rem;
                font-family: Tahoma;
                font-weight: 700;
                border-bottom: none;
                border-top: none;
            }
        }

        tbody {
            td {
                font-size: 0.49104859335038364rem;
                width: 11.743427109974425rem;
                border-right: 3px solid $white;
                font-family: Tahoma;
                font-weight: 400;
            }
        }
    }

    .nav-tabs {
        .nav-link {
            position: relative;
            margin: 0 1px;
            color: $orange;
            padding: 0px 50px 0px;
            font-size: 0.5728900255754475rem;
            font-weight: 700;
            font-family: Tahoma;
            border-radius: 0;
            border: 0;

            span {
                display: block;
                background: url('../../assets/images/tab_bg.png') repeat transparent;
                height: 75px;
                background-size: 100% 75px;
                padding: 0 2.0460358056265986rem;
                width: 100%;
                line-height: 75px;
            }

            &:before {
                content: "";
                top: 0px;
                left: 1px;
                width: 50px;
                height: 75px;
                position: absolute;
                background: url('../../assets/images/tab_left_bg.png') left center/100% 75px no-repeat !important;

                @media (min-width:992px) and (max-width:1200px) {
                    left: 1px;
                }
            }

            &:after {
                content: "";
                position: absolute;
                top: 0;
                right: 1px;
                width: 50px;
                height: 75px;
                background: url('../../assets/images/tab_right_bg.png') right center/100% 75px no-repeat !important;

                @media (min-width:992px) and (max-width:1200px) {
                    right: 1px;
                }
            }

            &:focus,
            &:hover,
            &.active {
                border: 0;

                span {
                    background: url('../../assets/images/tab_active_mid.png') repeat transparent;
                    background-size: 100% 75px;
                    padding: 0 2.0460358056265986rem;
                    color: #ffffff;
                }

                &:before {
                    background: url('../../assets/images/tab_active_left.png') left center/100% 75px no-repeat !important
                }

                &:after {
                    background: url('../../assets/images/tab_active_right.png') right center/100% 75px no-repeat !important
                }
            }
        }

        .nav-item {
            &.show {
                .nav-link {
                    color: $white;
                    border: 0;

                    &:focus,
                    &:hover,
                    &.active {
                        border: 0;
                    }
                }
            }
        }

    }
}

.search-red-btn {
    .Main-search {
        .RedButtonSearch-root-11 {
            @media (max-width: 767px) {
                width: 50%
            }
        }

        .MuiOutlinedInput-input {
            @media (max-width: 767px) {
                padding: 12.5px 14px;
            }
        }

        .btn {
            &.search-btn {
                width: 8.084143222506394rem;
                padding: 0.8184143222506394rem 0;
                background-color: $hover-field;
                border-color: $hover-field;

                @media (max-width: 767px) {

                    width: 6.184143222506394rem;
                    font-size: 12px !important;
                }
            }
        }
    }
}

.create-user {
    &.table-content {
        .client-data {
            padding: 0.20460358056265984rem 0.4092071611253197rem 0.6138107416879796rem 0.8184143222506394rem;

            .MuiPaper-elevation1 {
                .MuiTable-root {
                    td {
                        &.MuiTableCell-root {
                            padding: 0.6138107416879796rem 0.20460358056265984rem;
                        }
                    }
                }
            }
        }
    }
}

.css-1aarvou-DropdownHandleComponent svg {
    display: none !important;
}

.bg-dark-grey {
    background-color: $bg-dark-grey;
}

.btn-save {
    margin-left: auto !important;
}