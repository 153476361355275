$grey: #f5f6f8;
$blue: #006eb7;
$darken-blue: #2a333f;
$blue-text: #8ea6c9;
$lighten-blue: #169fdb;
$text-grey: #9b9b9b;
$dark-blue: #007efc;
$black: #000;
$transparent: transparent;
$light-silver: #fafafa;
$darken-brown: #4a4a4a;
$light-green: #68c0b5;
$light-white: #b2b2b2;
$dark-green: #b8e986;
$btn-light-grey: #c4c4c4;
$btn-green: #0a9b48;
$dark-brown: #cacaca;
$light-blue: #e5f0f8;
$white: #fff;
$text: #7f7f7f;
$text-color: #b2b2b2;
$ccGrey: #ccc;
$dardk-brown: #d8d8d8;
$dark-grey: #eee;
$border-color: #ced4da;
$light-grey: #d4d4d4;
$black: #000;
$orange: #f36b21;
$orange-hover: #bd541b;
$hover-field: #A91F2E;
$shadow-color:#F1F1F1;
$input-grey: #8C8C8C;
$bg-grey:#E5E5E5;
$form-field: #000;
$bg-dark-grey:#c4c4c4;