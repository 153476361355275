@import "../../assets/styles/variables.scss";
.dashboard-section{
    .tabs-section{
        .nav-link{
            span{
                padding: 0 1.227621483375959rem;
            }
            &:focus, &:hover, &.active{
                border: 0;
                span{
                    background: url('../../assets/images/tab_active_mid.png') repeat transparent;
                    background-size: 100% 75px;
                    padding: 0 1.227621483375959rem;
                    color: #ffffff;
                }
            }   
        }
        .tab-content{
            .tab-table{
                .table{
                    thead{
                        th{
                            font-size: 0.5728900255754475rem;
                        }
                    } 
                    tbody{
                        td{
                            font-size: 0.49104859335038364rem;
                            border-right: 3px solid $white;
                            padding: 0.20460358056265984rem;
                        }
                    }
                } 
            } 
        } 
    } 
}