@import "../../../assets/styles/variables.scss";

.login-form {
	overflow-y: hidden;
	.content-row {
		height: 100vh;
		align-items: center;

		.logo-img {
			width: 12.73913043478261rem;
			margin: auto;
			/*
			@media (max-width: 767px) {
				width: 100%;
			}
			*/
		}

		.register-box {
			box-shadow: 0px 5px 12px 4px $shadow-color;
			padding: 2.455242966751918rem 2.0460358056265986rem;

			h3 {
				font-size: 1.3094629156010231rem;
				color: $black;
				margin: 0 0 1.6368286445012787rem 0;
				font-weight: 700;
				font-family: Tahoma;
			}

			p {
				font-size: 0.6547314578005116rem;
				// Left aligns the error to to the border of the input
				margin: 0.02rem 0;
			}

			.color-alert {
				a {
					color: $hover-field;
					font-weight: 400;
					font-family: Tahoma;
				}
			}
			a {
				color: $blue;
				font-weight: 400;
				&:hover {
					color: $hover-field;
				}
			}
			.register-btn {
				button {
					background-color: $orange;
					border-color: $orange;
					font-family: Tahoma;
					font-weight: 700;
					letter-spacing: 1px;
					font-size: 0.6547314578005116rem;
					&:hover {
						background-color: $orange-hover;
					}
					@media (max-width: 767px) {
						font-size: 16px !important;
						padding: 10px;
					}
				}
			}
			span.error.text-danger {
				display: block;
				position: inherit;
			}
		}
	}
}
