@import "../../assets/styles/variables.scss";
body{
    font-family: Tahoma !important;
}
.table-content{
    .client-data{
        box-shadow: 6px 4px 20px $shadow-color;
        background: $white;
        padding: 0 1.6368286445012787rem 0 0;
        .MuiPaper-elevation1{
            box-shadow: none !important;
            .MuiTable-root{
                width: 99% !important;
                .MuiTableCell-root.MuiTableCell-head{
                    border-bottom: none !important;
                    font-family: Tahoma;
                    font-weight: 700;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 0.5728900255754475rem;
                }
                td.MuiTableCell-root{
                    border-bottom: none !important;
                    font-family: Tahoma;
                    font-weight: 400  !important;
                    font-size: 0.5319693094629157rem !important;
                    // padding: 0 0;
                    .MuiCheckbox-root.bg-grey{
                        background: $bg-grey;
                        border-radius: 0 !important;
                    }
                    .MuiCheckbox-colorSecondary.Mui-checked{
                        color: $orange !important;
                    }
                }
                td.MuiTableCell-root.bg-grey{
                    background: $bg-grey;
                    border-right: 5px solid $white;
                }
            }
        }
    }
}

.btn{
    &.main-btn{
        font-family: Tahoma;
        font-weight: 400;
        letter-spacing: 1px;
        width: 170px;
        font-size: 0.6547314578005116rem;
        // margin-top: 0.8184143222506394rem;
        @media (max-width:767px) {
            font-size: 16px !important;
            padding: 10px;
        }
        &.btn-right{
            float: right;
        }
        &.default-btn{
            background-color: $hover-field;
            border-color: $hover-field;
            margin-right: 0.8184143222506394rem;
            &:hover{
                background-color: $hover-field;
            }
        }
        &.orange-btn{
            background-color: $orange;
            border-color: $orange;
            &:hover{
                background-color: $orange-hover;
            }
        }
    }
}
.MuiRadio-colorSecondary{
    &.Mui-checked{
        color: $orange !important;
    }
}