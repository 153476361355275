@import "../../assets/styles/variables.scss";
.tabs-section{
    .nav-tabs {
        .nav-link{
            position: relative;
            margin: 0 1px;
            color: $orange;
            padding: 0px 50px 0px;
            font-size: 0.5728900255754475rem;
            font-weight: 700;
            font-family: Tahoma;
            border-radius: 0;
            border: 0;
            span{
                display: block;
                background: url('../../assets/images/tab_bg.png') repeat transparent;
                height: 75px;
                background-size: 100% 75px;
                padding: 0 0.5319693094629157rem;
                width: 100%;
                line-height: 75px;
            }
            &:before{
                content: "";
                top: 0px;
                left: 1px;
                width: 50px;
                height: 75px;
                position: absolute;
                background: url('../../assets/images/tab_left_bg.png') left center/100% 75px no-repeat !important;
                @media (min-width:992px) and (max-width:1200px) {
                    left: 1px;
                }
            }
            &:after{
                content: "";
                position: absolute;
                top: 0;
                right: 1px;
                width: 50px;
                height: 75px;
                background: url('../../assets/images/tab_right_bg.png') right center/100% 75px no-repeat !important;
                @media (min-width:992px) and (max-width:1200px) {
                  right: 1px;
                }
            }
            &:focus, &:hover, &.active{
                border: 0;
                span{
                    background: url('../../assets/images/tab_active_mid.png') repeat transparent;
                    background-size: 100% 75px;
                    padding: 0 0.5319693094629157rem;
                    color: #ffffff;
                }
                &:before{
                    background: url('../../assets/images/tab_active_left.png') left center/100% 75px no-repeat !important
                }
                &:after{
                    background: url('../../assets/images/tab_active_right.png') right center/100% 75px no-repeat !important
                }
            }   
        }
        .nav-item{
            &.show {
                .nav-link{
                    color: $white ;
                    border: 0;
                    &:focus, &:hover, &.active{
                        border: 0;
                    }   
                }
            }
        }
        
    }
    
    .tab-content{
        padding: 0.8184143222506394rem 0.6368286445012787rem;
        box-shadow: -1px 2px 20px $shadow-color;
        .tab-table {
            .table {
                td{
                    border-top: none;
                    border-right: 10px solid $white;
                    font-size: 0.5728900255754475rem;
                    font-weight: 400;
                    color:$black;
                    vertical-align: middle;
                    font-family: Tahoma !important;
                    .view-btn{
                        font-weight: 400;
                        font-family: Tahoma !important;
                        margin-top: 0;
                        margin-right: 0;
                        width: 100%;
                        background-color: $input-grey;
                        border: $input-grey;
                        box-shadow: -1px 2px 20px $shadow-color;
                        padding: 0.4728900255754475rem 0;
                        &:hover{
                            background-color: $orange;
                        }
                    }
                }
                thead {
                    th{
                        border-bottom: none;
                        border-top: none;
                        font-size: 0.6547314578005116rem; 
                        font-weight: 700;
                        font-family: Tahoma !important;
                        color: $black;
                    }
                }
            }
        }
    }
}