@import "../../assets/styles/variables.scss";
body {
    font-family: Tahoma !important;
}
.Main-search {
    .MuiInputBase-input {
        &:focus {
            .MuiIconButton-label {
                color: $hover-field !important;
                outline: none !important;
            }
        }
    }
    .btn {
        &.search-btn {
            background-color: $orange;
            width: 7.242966751918159rem;
            border-color: $orange;
            font-family: Tahoma;
            font-weight: 700;
            letter-spacing: 1px;
            font-size: 0.6547314578005116rem;
            padding: 0.5184143222506394rem 1.864450127877238rem;
            margin-left: 0.6184143222506394rem;
            &:hover {
                background-color: $orange-hover;
                border-color: $orange-hover;
            }
            @media (max-width: 767px) {
                font-size: 16px !important;
                padding: 10px;
            }
        }
    }
    .MuiFormControl-root{
        width: 78%;
        @media (min-width:1130px) and (max-width:1200px) {
            width: 74%;
            }
        @media (min-width:767px) and (max-width:1129px) {
            width: 72%;
        }
        .MuiIconButton-root {
            &:focus {
                outline: none !important;
            }
            .MuiTouchRipple-root {
                &:focus {
                    outline: none !important;
                }
            }
            &:focus {
                outline: none;
            }
        }
    }
}
button:focus {
    &:focus {
        outline: none !important;
    }
}