@import "../../assets/styles/variables.scss";

.logo {
  height: 100vh;
  align-items: center;
  img {
    margin: auto;
    width: 12.73913043478261rem;
  }
}
